<template>
  <div
    class="user-profile-sidebar"
    :class="{'show': shallShowActiveChatContactSidebar}"
  >
    <!-- Header -->
    <header
      
      class="user-profile-header"
    >
      <span class="close-icon">
        <feather-icon
          icon="XIcon"
          @click="$emit('update:shall-show-active-chat-contact-sidebar', false)"
        />
      </span>

      <div class="header-profile-sidebar">
        <div class="avatar box-shadow-1 avatar-xl avatar-border">
          <!-- <b-avatar
            size="70"
            :src="pesan.from.avatar"
          /> -->
          <!-- <span
            class="avatar-status-xl"
            :class="`avatar-status-${contact.status}`"
          /> -->
        </div>
        <h4 class="chat-user-name">
          <!-- {{ pesan.from.name }} -->
        </h4>
        <!-- <span class="user-post text-capitalize">{{ contact.role }}</span> -->
      </div>
    </header>

    <!-- User Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="user-profile-sidebar-area scroll-area"
    >

      <h6 class="section-label mb-1">
        Pertanyaan
      </h6>
      <p>{{ dataPertanyaan }}</p>
  
      <!-- Personal Info -->
      <div class="personal-info">
        <h6 class="section-label mb-1 mt-3">
          Pilih Pertanyaan paling mirip?
        </h6>
        <div class="custom-control custom-radio" v-for="jawaban in clusterJawaban" :key="jawaban">
          <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input" :value="jawaban.cluster" v-model="jawabanAtasPertanyaan">
          <label class="custom-control-label" for="customRadio1">{{jawaban.cluster + " ("+jawaban.kemiripan+"%)"}} <a href="#modal-1" @click="showModal(jawaban.cluster)" data-toggle="modal" data-target="#modal-1"><i class="fa fa-circle-info"></i></a></label>
        </div>
        <div class="custom-control custom-radio">
         <a href="#">
          <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input">

          <label class="custom-control-label" for="customRadio2">Tidak Ada</label>
          </a>
        </div>
      </div>
      <div style="margin-top:20px" class="d-flex justify-content-center">
        <button style="margin-right:5px" type="button" class="btn btn-outline-info" @click="lihatJawaban(jawabanAtasPertanyaan)">Lihat Jawaban</button>
        
        <button v-if="isChecked == true" style="margin-left:5px" type="button" class="btn btn-outline-success" @click="showModalTidakAda()">Submit</button>
        <button v-else style="margin-left:5px" type="button" class="btn btn-outline-success" @click="$emit('submitJawaban',jawabanAtasPertanyaan)">Submit</button>
      </div>
    </vue-perfect-scrollbar>

    <b-modal
      ref="my-modalLihat"
      hide-footer
      title="Jawaban yang terkait:"
    >
      <div class="d-block">

        <ul class="list-group">
          <li class="list-group-item" v-for="jb in dataJawaban" :key="jb">{{jb.jawaban}}</li>
        </ul>
      </div>
    </b-modal>

    <b-modal
      ref="my-modal"
      hide-footer
      title="Kelompok Pertanyaan:"
    >
      <div class="d-block">

        <ul class="list-group">
          <li class="list-group-item" v-for="pt in dataPertanyaancluster" :key="pt">{{pt.pertanyaan}}</li>
        </ul>
      </div>
    </b-modal>
    
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BAvatar } from 'bootstrap-vue'
import axios from 'axios'

export default {
  components: {
    // BSV
    BAvatar,

    // 3rd Party
    VuePerfectScrollbar,
  },
  props: {
    shallShowActiveChatContactSidebar: {
      type: Boolean,
      required: true,
    },
    pesan: {
      type: Object,
      required: true,
    },
    clusterJawaban:{
      type: Object,
      required:true,
    },
    dataPertanyaan:{
      type: String,
      required: true,
    }
  },
  data() {
    return {
      jawabanAtasPertanyaan:'',
      dataJawaban:[],
      userData : JSON.parse(localStorage.getItem('userData')),
      url: localStorage.getItem('baseapi'),
      dataPertanyaancluster:[]
    }
  },
  methods: {
  lihatJawaban(data) {
      this.$refs['my-modalLihat'].show()
      console.log(data)
      axios.get(this.url+'cluster?filter=nama_cluster,=,'+data,
      {
        headers:{
          xth : this.userData.token
        }
      }).then(response => {
        console.log(response);
        this.dataJawaban = response.data.data
      })
      .catch(function (error) {
        console.log(error);
      });
      
    },
    showModal(data) {
      this.$refs['my-modal'].show()
      axios.get(this.url+'pertanyaan_cluster?filter=cluster,=,'+data,
      {
        headers:{
          xth : this.userData.token
        }
      }).then(response => {
        console.log(response);
        this.dataPertanyaancluster = response.data.data
      })
      .catch(function (error) {
        console.log(error);
      });
      
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    return {
      perfectScrollbarSettings,
    }
  },
}
</script>

<style>

</style>
