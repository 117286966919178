<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Cek Pertanyaan
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <div class="px-2 py-1">
        <h6 class="section-label mb-1">
          Pertanyaan
        </h6>
        <p>{{ dataPertanyaan }}</p>

        <div>
          <h6 class="section-label mb-1 mt-3">
            Pilih Pertanyaan paling mirip?
          </h6>
          <div class="custom-control custom-radio" v-for="jwbn in clusterJawaban" :key="jwbn">
            <input type="radio" :id="jwbn.cluster" name="customRadio" class="custom-control-input" :value="jwbn.cluster" v-model="jawabanAtasPertanyaan">
            <label class="custom-control-label" :for="jwbn.cluster">{{jwbn.cluster + " ("+jwbn.kemiripan+"%)"}} <a href="#modal-1" @click="showModal(jwbn.cluster)" data-toggle="modal" data-target="#modal-1"><i class="fa fa-circle-info"></i></a></label>
          </div>
          <div class="custom-control custom-radio">
          <a href="#">
            <input type="radio" id="customRadio2" name="customRadio" @click="checkData()" :checked="isChecked" value="Tidak" class="custom-control-input">

            <label class="custom-control-label" for="customRadio2">Tidak ada</label>
            </a>
          </div>
        </div>
        <div style="margin-top:20px" class="d-flex justify-content-center">
          <button style="margin-right:5px" type="button" class="btn btn-outline-info" @click="lihatJawaban(jawabanAtasPertanyaan)">Lihat Jawaban</button>
          
          <button v-if="isChecked == true" style="margin-left:5px" type="button" class="btn btn-outline-success" @click="showModalTidakAda(); $emit('addMapping')">Submit</button>
          <button v-else style="margin-left:5px" type="button" class="btn btn-outline-success" @click="$emit('submitJawaban',jawabanAtasPertanyaan)">Submit</button>
          </div>
        </div>

      <b-modal
        ref="my-modal"
        hide-footer
        title="Kelompok Pertanyaan:"
      >
        <div class="d-block">

          <ul class="list-group">
            <li class="list-group-item" v-for="pt in dataPertanyaancluster" :key="pt">{{pt.pertanyaan}}</li>
          </ul>
        </div>
      </b-modal>

      <b-modal
        ref="my-modalLihat"
        hide-footer
        title="Jawaban yang terkait:"
      >
        <div class="d-block">

          <ul class="list-group">
            <li class="list-group-item" v-for="jb in dataJawaban" :key="jb">{{jb.jawaban}}</li>
          </ul>
        </div>
      </b-modal>

      <b-modal
        ref="my-modaltidakada"
        hide-footer
        title="Form Pertanyaan:"
      >
        <div class="d-block">
          <div class="form-group">
            <label for="exampleInputEmail1">Nama Kelompok Pertanyaan</label>
            <input type="email" class="form-control" v-model="nama_cluster" id="exampleInputEmail1" aria-describedby="emailHelp">
          </div>
          <div class="form-group">
            <label for="exampleFormControlSelect1">Dipetakan Kepada</label>
            <select class="form-control" id="exampleFormControlSelect1" v-model="expert" >
              <option v-for="dt in listExpert" :key="dt">{{dt.nama}}</option>
            </select>
          </div>
        </div>

        <b-button variant="primary" class="float-right" @click="simpanPertanyaanBaru(idpertanyaan)">Simpan</b-button>
      </b-modal>



      
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BModal, BFormRadioGroup
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import axios from 'axios'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BModal,
    BFormRadioGroup,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    dataPertanyaan: {
      type: String,
      required: true,
    },
    idpertanyaan: {
      type: String,
      required: true,
    },
    clusterJawaban:{
      type: Object,
      required:true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      jawabanAtasPertanyaan:'',
      dataJawaban:[],
      userData : JSON.parse(localStorage.getItem('userData')),
      url: localStorage.getItem('baseapi'),
      dataPertanyaancluster:[],
      isChecked: false,
      listExpert:[],
      nama_cluster:null,
      expert: null,
    }
  },
  methods: {
    loadData(){
      axios.get(this.url+'user?filter=hak_akses,=,expert',
      {
        headers:{
          xth: this.userData.token
        }
      }).then(response =>{
       
        this.listExpert = response.data.data
        console.log( this.listExpert);
      }).catch(function (error) {
        console.log(error);
      });
    },
    lihatJawaban(data) {
      this.$refs['my-modalLihat'].show()
      console.log(data)
      axios.get(this.url+'cluster?filter=nama_cluster,=,'+data,
      {
        headers:{
          "xth" : this.userData.token
        }
      }).then(response => {
        console.log(response);
        this.dataJawaban = response.data.data
      })
      .catch(function (error) {
        console.log(error);
      });
      
    },
    showModalTidakAda() {
      this.$refs['my-modaltidakada'].show()
    },
    showModal(data) {
      this.$refs['my-modal'].show()
      axios.get(this.url+'pertanyaan_cluster?filter=cluster,=,'+data,
      {
        headers:{
          "xth" : this.userData.token
        }
      }).then(response => {
        console.log(response);
        this.dataPertanyaancluster = response.data.data
        console.log(response.data.data);
        console.log('dataPertanyaancluster')
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    checkData() {
      if(this.isChecked == true){
        this.isChecked = false;
      }else{
         this.isChecked = true;
      }
      
      console.log(this.isChecked)
      
    },
    simpanPertanyaanBaru(idpertanyaan){
      //var detail = detailforSubmit.value
      console.log(idpertanyaan)
      axios.post(this.url+'cluster',
      {
        nama_cluster: this.nama_cluster,
        pertanyaan_id: idpertanyaan,
        expert: this.expert
      },{
        headers:{
          xth:this.userData.token
        }
      }).then(response => {
        console.log(response);
        this.makeToast("success",response.data.message)
        this.hideModalTidakAda()
        // this.$emit('update:is-add-new-user-sidebar-active', false)
      })
      .catch(function (error) {
        console.log(error);
        this.makeToast("error",response.data.message)
      });
    },
    makeToast(variant = null,pesan) {
      this.$bvToast.toast(pesan, {
        title: `${variant || 'default'}`,
        variant,
        solid: false,
      })
    },
    hideModalTidakAda() {
      this.$refs['my-modaltidakada'].hide()
    },
  },
  mounted(){
    this.loadData()
  },
  setup() {
    // const blankUserData = {
    //   fullName: '',
    //   username: '',
    //   email: '',
    //   role: null,
    //   currentPlan: null,
    //   company: '',
    //   country: '',
    //   contact: '',
    // }

    // const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    // const dataPertanyaancluster = ref([])
    // const resetuserData = () => {
    //   userData.value = JSON.parse(JSON.stringify(blankUserData))
    // }

    // const onSubmit = () => {
    //   store.dispatch('app-user/addUser', userData.value)
    //     .then(() => {
    //       emit('refetch-data')
    //       emit('update:is-add-new-user-sidebar-active', false)
    //     })
    // }

    // const showModal = data => {
    //   this.$refs['my-modal'].show()
    //   axios.get(this.url+'pertanyaan_cluster?filter=cluster,=,'+data,
    //   {
    //     headers:{
    //       "xth" : this.userData.token
    //     }
    //   }).then(response => {
    //     console.log(response);
    //     dataPertanyaancluster = response.data.data
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
    // }

    // const {
    //   refFormObserver,
    //   getValidationState,
    //   resetForm,
    // } = formValidation(resetuserData)

    return {
      // onSubmit,

      // refFormObserver,
      // getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
