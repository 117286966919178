<template>
  <div class="email-app-details">

    <!-- Email Header -->
    <div class="email-detail-header">

      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-email-view'); $emit('data-baru')"
          />
        </span>
        <h4 class="email-subject mb-0">
          {{ emailViewData.chat_id }}
        </h4>
      </div>

      <!-- Header: Right -->
      <div class="email-header-right ml-2 pl-1">
        <div class="d-flex align-items-center">
             <b-button variant="success" @click="isAddNewUserSidebarActive = true">Cek Pertanyaan</b-button>
             <b-button variant="danger" class="ml-2" v-if="emailViewData.status !='Resolved'" @click="resolveChat(emailViewData.id)">Resolve</b-button>
        </div>
      </div>
    </div>

    <!-- Email Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="email-scroll-area scroll-area"
    >

      <!-- Email Thread -->
      <br>
      <b-row>
        <b-col cols="12">
          <!-- {{formattedChatData}} -->
          <!-- <email-message-card :message="emailViewData" :Jawaban="cluster"   @getPertanyaan="ambilPertanyaan" /> -->
          <b-card no-body v-for="message in emailViewData.chat" :key="message">
            <b-card-header
              class="email-detail-head"
            >
              <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
                <b-avatar
                  size="48"
                  :text="avatarText(message.pengirim)"
                  class="mr-75"
                  @click.native="shallShowActiveChatContactSidebar=true"
                />
                <div class="mail-items">
                  <h5 class="mb-0">
                    {{ message.pengirim }}
                  </h5>
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    class="email-info-dropup"
                  >
                    <template #button-content>
                      <span v-if="message.pengirim === 'Me' || message.pengirim === userData.nama" class="font-small-3 text-muted mr-25">lwpi@mysurvey.id</span>
                      <span v-else class="font-small-3 text-muted mr-25">{{ message.chat_id }}</span>
                      <feather-icon
                        icon="ChevronDownIcon"
                        size="10"
                      />
                    </template>
                    <table class="table table-sm table-borderless font-small-3">
                      <tbody>
                        <tr>
                          <td class="text-right text-muted align-top">
                            From:
                          </td>
                          <td>{{ message.pengirim }}</td>
                        </tr>
                        <tr>
                          <td class="text-right text-muted align-top">
                            To:
                          </td>
                          <td>{{ message.penerima }}</td>
                        </tr>
                        <tr>
                          <td class="text-right text-muted align-top">
                            Date:
                          </td>
                          <td>{{ message.created_at }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </b-dropdown>
                </div>
              </div>
              <div class="mail-meta-item d-flex align-items-center">
                <small class="mail-date-time text-muted">{{ formatDate(message.created_at) }}</small>
              </div>
            </b-card-header>

            <b-card-body class="mail-message-wrapper pt-2">
              <div v-if="message.pengirim === 'Me' || message.pengirim === userData.nama || message.pengirim != emailViewData.owner">
                  
              </div>
              <div v-else  class="form-group form-check" style="margin-bottom:20px">
                <input type="checkbox"  v-model="datacheklist" class="form-check-input" id="exampleCheck1" :value='message' @change="ambilPertanyaan(datacheklist)">
              </div>
              <h5 class="font-weight-bolder text-body mb-0">
                  Subject : 
              </h5>
              <div
                class="mail-message"
              >{{ message.pesan.subject }}</div>
              <br>
              <h5 class="font-weight-bolder text-body mb-0">
                  Pesan : 
              </h5>
              <!-- <br> -->
              <div
                class="mail-message"
                v-html="message.pesan.body"
              />
              <br>
              <h5 v-if="message.pesan.attachment.length != 0" class="font-weight-bolder text-body mb-0">
                  Attachment :
              </h5>
              <!-- <br> -->
              <div class="mail-message" v-for="file in message.pesan.attachment" :key="file">
                <!-- <ul v-for="file in message.pesan.attachment" :key="file">
                  <li> -->
                    <div v-if="cekEkstensi(file) === 'jpeg' || cekEkstensi(file) === 'jpg' || cekEkstensi(file) === 'png'">
                      <img :src="file" width="300" class="img-thumbnail" :alt="file">
                    </div>
                    <div v-else-if="cekEkstensi(file) === 'pdf' || cekEkstensi(file) === 'doc'">
                      <a :href="file" target="_blank">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="secondary"
                        type="submit"
                      >
                        <feather-icon
                          icon="FileTextIcon"
                          class="mr-50"
                        />
                        <i data-feather='file'></i>
                        <span class="align-middle">{{getNamaFile(file)}}</span>
                      </b-button>
                      </a>
                    
                    </div>
                    <!-- <a :href="file">{{file}}</a>
                  </li>
                </ul> -->
              </div>

              <div v-if="message.pengirim !='Me' && message.pengirim != userData.nama && message.pengirim === emailViewData.owner">
                  <b-badge :variant="statusVariant(message.status)">
                  {{message.status}}
                </b-badge>
              </div>
              <!-- eslint-enable -->
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <!-- Email Actions: Reply or Forward -->
      <b-row>
        <b-col cols="12">
          <b-card>
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">
                Click here to
                <b-link @click="$emit('update:shall-show-email-compose-modal', true); $emit('close-left-sidebar')">Reply</b-link>
              </h5>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </vue-perfect-scrollbar>

    <sidebar-email
     :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" 
     :shall-show-email-compose-modal.sync="shallShowEmailComposeModal"
     :dataPertanyaan="chekedPertanyaan"
     :clusterJawaban="cluster || {}"
     @submitJawaban="ambilJawaban"
     :idpertanyaan="dataidPertanyaan"
     @addMapping="addmapping"
     />

     <!-- <email-compose-reply  v-model="shallShowEmailComposeModal" /> -->
      <b-modal
          id="compose-mail"
          v-model="modalShow"
          title="Compose Email"
          modal-class="modal-sticky"
          footer-class="d-flex justify-content-between"
          body-class="p-0"
          size="lg"
          no-fade
          hide-backdrop
          static
        >

        <!-- Modal Header -->
        <template #modal-header>
          <h5 class="modal-title">
            Compose Mail
          </h5>
          <div class="modal-actions">
            <feather-icon
              icon="MinusIcon"
              class="cursor-pointer"
            />
            <feather-icon
              icon="Maximize2Icon"
              class="ml-1 cursor-pointer"
            />
            <feather-icon
              icon="XIcon"
              class="ml-1 cursor-pointer"
              @click="discardEmail"
            />
          </div>
        </template>

        <!-- Modal Footer -->
        <!-- Modal Footer -->
        <template #modal-footer>
          <!-- Footer: Left Content -->
          <div>
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              split
              text="Send"
              variant="primary"
              right
              @click="sendEmail"
            >
            </b-dropdown>
            <div class="file btn btn-lg" style="position: relative;overflow: hidden;">
						  <i class="fa fa-paperclip"></i>
						  <input style="position: absolute; font-size: 50px; opacity: 0; right: 0;top: 0;" type="file" ref="file" name="file" @change="kirimfile()"/>
				    </div>
          </div>

          <!-- Footer: Right Content -->
          <div>

            <!-- Discard Compose -->
            <feather-icon
              icon="TrashIcon"
              size="17"
              class="ml-75 cursor-pointer"
              @click="discardEmail"
            />
          </div>
        </template>

        <!-- Modal: Body -->
        <b-form>

          <!-- Field: To -->
          <div class="compose-mail-form-field">
            <label
              for="email-to"
              class="form-label"
            >To: </label>
            <b-form-input
              id="email-subject"
              v-model="to"
            />
          </div>

          <!-- Field: Subject -->
          <div class="compose-mail-form-field">
            <label for="email-subject">Subject: </label>
            <b-form-input
              id="email-subject"
              v-model="subject"
            />
          </div>

          <!-- Field: Message - Quill Editor -->
          <div class="message-editor">
            <quill-editor
              id="quil-content"
              :options="editorOption"
              v-model="jawabanBody"
            />
            <div
              id="quill-toolbar2"
              class="d-flex border-bottom-0"
            >
              <!-- Add a bold button -->
              <button class="ql-bold" />
              <button class="ql-italic" />
              <button class="ql-underline" />
              <button class="ql-align" />
              <button class="ql-link" />
            </div>
          </div>
        </b-form>

    </b-modal>
  </div>
</template>

<script>
import {
  BFormInput,BModal,BDropdown, BDropdownDivider,BDropdownItem, BRow, BCol, BBadge, BCard, BLink,BButton, BCardHeader, BCardBody, BCardFooter, BAvatar, BImg
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ref, watch, computed } from '@vue/composition-api'
import useEmail from './useEmail'
import EmailMessageCard from './EmailMessageCard.vue'
import { avatarText } from '@core/utils/filter'
import axios from "axios"
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import { formatDate } from '@core/utils/filter'
import SidebarEmail from './SidebarEmail.vue'
import EmailComposeReply from './EmailCompose2.vue'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'


export default {
  directives: {
    Ripple,
  },
  components: {

    // BSV
    BFormInput,
    BModal,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BBadge,
    BCard,
    BLink,
    BButton,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BAvatar,
    BImg,
    SidebarEmail,
    EmailComposeReply,
    BDropdownDivider,


    // 3rd Party
    VuePerfectScrollbar,
    quillEditor,
    vSelect,

    // SFC
    EmailMessageCard,
  },
  props: {
    emailViewData: {
      type: Object,
      required: true,
    },
    opendedEmailMeta: {
      type: Object,
      required: true,
    },
  },
  methods: {
    cekEkstensi(input){
      return input.split(/[. ]+/).pop();
    },
    getNamaFile(input){
      return input.split(/[/ ]+/).pop();
    },
  },
  setup(props,{ emit }) {
    const { resolveLabelColor } = useEmail()
    const userData = JSON.parse(localStorage.getItem('userData'));
    const url = localStorage.getItem('baseapi');
    // Compose
    const shallShowEmailComposeModal = ref(false)

    const dataemail = props.emailViewData

    const isAddNewUserSidebarActive = ref(false)

    const statusVariant = computed(() =>{
      const statusColor = {
        /* eslint-disable key-spacing */
        "Answered by Expert"      : 'light-primary',
        "Resolved" : 'light-success',
        "Returned by Expert"     : 'light-danger',
        "Answered"      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    })

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const showWholeThread = ref(false)

    watch(() => props.emailViewData.id, () => {
      showWholeThread.value = false
    })


    const chekedPertanyaan = ref('')
    const jawabanBody = ref('')
    const subject = ref('')
    const to = ref('')
    const cluster = ref([])
    const datacheklist = ref([])
    const dataidPertanyaan = ref([])

    const ambilPertanyaan = data =>{
      // console.log(data)
      var dataku =[]
      for(var i = 0;i<data.length;i++){
        // dataku.push(data[i])
        // console.log(data[i].pesan.body)
        dataku.push(data[i].pesan.body)
      }

      subject.value =data[0].pesan.subject
      
      chekedPertanyaan.value = dataku.join(' ')
      console.log(chekedPertanyaan)
      dataidPertanyaan.value = data[0].id
      console.log("dataidPertanyaan")
      console.log(dataidPertanyaan.value)

      axios.post(url+'cluster_recomendation',{
        pertanyaan: chekedPertanyaan.value
      },
      {
        headers: {
          xth: userData.token
        }
      }
      )
      .then(function (response) {
        cluster.value = response.data.data
        console.log('cluster')
        console.log(cluster);
      })
      .catch(function (error) {
        console.log(error);
      });
        }

    const modalShow = ref(false)

    const ambilJawaban = data => {
      
      var datachatActive = props.emailViewData
      axios.post(url+'pertanyaan_cluster',
      {
        chat_id: datachatActive.chat_id,
        pertanyaan_id: dataidPertanyaan.value,
        penanya: datachatActive.owner,
        pertanyaan: chekedPertanyaan.value,
        cluster: data
      },{
        headers:{
          "xth":userData.token
        }
      }).then(response => {
        console.log(response.data.data);
        var dataJawaban = response.data.data;
        console.log('datajawaban')
        jawabanBody.value = dataJawaban.jawaban
        console.log(jawabanBody.value)
        modalShow.value = true
        to.value = props.emailViewData.chat_id
        // dataFile.value = dataJawaban.filename

      })
      .catch(function (error) {
        console.log(error);
      });
    }

    const addmapping = () => {
      var datachatActive = props.emailViewData
      var cluster = "Tidak ada"
      axios.post(url+'pertanyaan_cluster',
      {
        chat_id: datachatActive.chat_id,
        pertanyaan_id: dataidPertanyaan.value,
        penanya: datachatActive.owner,
        pertanyaan: chekedPertanyaan.value,
        cluster: cluster
      },{
        headers:{
          "xth":userData.token
        }
      }).then(response => {
        console.log(response.data.data);
        console.log('addmapping')
        // dataFile.value = dataJawaban.filename

      })
      .catch(function (error) {
        console.log(error);
      });
    }

    const discardEmail = () => {
      modalShow.value = false
    }

    const file = ref(null)
    const fileAttach = ref(null)
    const kirimfile=()=>{
      console.log('attach')
      fileAttach.value = file.value.files[0]
      console.log(fileAttach.value)
    }

    const sendEmail = () => {
      composeData.value = {}
      console.log(props.emailViewData.chat_id)

       let formData = new FormData();
        formData.append('to', to.value,);
        formData.append('subject', "Re: "+subject.value);
        formData.append('body', jawabanBody.value);
        formData.append('nama', userData.nama);
        formData.append('attachment', fileAttach.value);
      axios.post(url+'Email/sendMessage',formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                xth: userData.token 
            }
        })
        .then(response => {
          console.log(response)
          // this.$bvToast.toast('Toast body content', {
          //           title: `Sukses`,
          //           variant: success,
          //           solid: true
          //         })
          fileAttach.value = null
          modalShow.value = false
          // discardEmail()

        }).catch(error => {
          console.log(error)
        })

        // emit('update:shall-show-email-compose-modal', false)
      

      // ? Send your Email
    }

    const resolveChat = idchat => {
      // Reset send message input value

      axios.put(url+'room/'+idchat,{
        status: 'Resolved',
        },{
          headers:{
            'xth': userData.token
          }
        })
        .then(response => {
          
          console.log(response)
          emit('close-email-view')
          emit('load-data',props.emailViewData.chat_id)
        })

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      //nextTick(() => { scrollToBottomInChatLog() })
    }

    const composeData = ref({})
    const showCcField = ref(false)
    const showBccField = ref(false)

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar2',
      },
      placeholder: 'Message',
    }

    /* eslint-disable global-require */
    const emailToOptions = [
      { avatar: require('@/assets/images/avatars/1-small.png'), name: 'Jane Foster' },
      { avatar: require('@/assets/images/avatars/3-small.png'), name: 'Donna Frank' },
      { avatar: require('@/assets/images/avatars/5-small.png'), name: 'Gabrielle Robertson' },
      { avatar: require('@/assets/images/avatars/7-small.png'), name: 'Lori Spears' },
      { avatar: require('@/assets/images/avatars/9-small.png'), name: 'Sandy Vega' },
      { avatar: require('@/assets/images/avatars/11-small.png'), name: 'Cheryl May' },
    ]

    return {

      // UI
      perfectScrollbarSettings,
      showWholeThread,
      modalShow,
      editorOption,
      composeData,
      showCcField,
      showBccField,
      subject,
      to,

      

      // useEmail
      resolveLabelColor,
      avatarText,
      formatDate,
      userData,
      isAddNewUserSidebarActive,
      ambilPertanyaan,
      chekedPertanyaan,
      datacheklist,
      cluster,
      ambilJawaban,
      dataemail,
      shallShowEmailComposeModal,
      jawabanBody,
      discardEmail,
      sendEmail,
      dataidPertanyaan,
      kirimfile,
      file,
      fileAttach,
      resolveChat,
      addmapping,
      statusVariant
      // ambilPertanyaan
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
form ::v-deep {

  // Mail To vue-select style
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
    }
    .vs__open-indicator {
      display: none;
    }
  }

  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
}
</style>
