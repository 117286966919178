<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar || shallShowActiveChatContactSidebar }"
      @click="mqShallShowLeftSidebar = shallShowActiveChatContactSidebar = false"
    />

    <!-- Email List -->
    <div class="email-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true;"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <h4 class="ml-0 input-group-merge" style="color:black">List Chat</h4>
          <!-- <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
                @click="shallShowActiveChatContactSidebar = true"
              />
            </b-input-group-prepend>
            <b-form-input
              :value="searchQuery"
              placeholder="Search email"
              @input="updateRouteQuery"
            />
          </b-input-group> -->
        </div>
      </div>

      <!-- App Action Bar -->
      

      <!-- Emails List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="email-user-list scroll-area"
      >
        <ul class="email-media-list">
          <div class="mb-2">
            <h4 class="chat-list-title ml-1 mt-1 text-primary">
                Unassigned
            </h4>
            <b-media
              v-for="email in emails"
              :key="email.id"
              tag="li"
              no-body
              
              @click="updateEmailViewData(email.id)"
              v-if="email.status === 'Unassigned' || email.status === 'Returned by Expert'"
            >
              <!-- Contacts Title -->
              
              <b-media-aside class="media-left mr-50">
                <b-avatar
                  class="avatar"
                  size="40"
                  variant="primary"
                  :text="avatarText(email.userId)"
                />
                <div class="user-action">
                  <div class="email-favorite">
                  </div>
                </div>
              </b-media-aside>

              <b-media-body>
                <div class="mail-details">
                  <div class="mail-items">
                    <h5 class="mb-25">
                      {{ email.userId }}
                    </h5>
                    <span class="text-truncate">{{ email.pesanTerakhir.subject }}</span>
                  </div>
                  <!-- <div class="mail-meta-item">
                    <feather-icon
                      v-if="email.attachments.length"
                      icon="PaperclipIcon"
                    />
                    <span
                      v-for="label in email.labels"
                      :key="label"
                      class="mx-50 bullet bullet-sm"
                      :class="`bullet-${resolveLabelColor(label)}`"
                    />
                    <span class="mail-date">{{ formatDateToMonthShort(email.time, { hour: 'numeric', minute: 'numeric', }) }}</span>
                  </div> -->
                </div>

                <div class="mail-message">
                  <p class="text-truncate mb-0">
                    {{ filterTags(email.pesanTerakhir.body) }}
                  </p>
                </div>
              </b-media-body>
            </b-media>
          </div>
          <div class="mb-2">
            <h4 class="chat-list-title ml-1 text-primary">
              Answered by Expert
            </h4>
            <b-media
              v-for="email in emails"
              :key="email.id"
              tag="li"
              no-body
              
              @click="updateEmailViewData(email.id)"
              v-if="email.status === 'Answered by Expert'"
            >
              <!-- Contacts Title -->
              
              <b-media-aside class="media-left mr-50">
                <b-avatar
                  class="avatar"
                  size="40"
                  variant="primary"
                  :text="avatarText(email.userId)"
                />
                <div class="user-action">
                  <!-- <b-form-checkbox
                    :checked="selectedEmails.includes(email.id)"
                    @change="toggleSelectedMail(email.id)"
                    @click.native.stop
                  /> -->
                  <div class="email-favorite">
                    <!-- <feather-icon
                      icon="StarIcon"
                      size="17"
                      :class="{ 'text-warning fill-current': email.isStarred }"
                      @click.stop="toggleStarred(email)"
                    /> -->
                  </div>
                </div>
              </b-media-aside>

              <b-media-body>
                <div class="mail-details">
                  <div class="mail-items">
                    <h5 class="mb-25">
                      {{ email.userId }}
                    </h5>
                    <span class="text-truncate">{{ email.pesanTerakhir.subject }}</span>
                  </div>
                  <!-- <div class="mail-meta-item">
                    <feather-icon
                      v-if="email.attachments.length"
                      icon="PaperclipIcon"
                    />
                    <span
                      v-for="label in email.labels"
                      :key="label"
                      class="mx-50 bullet bullet-sm"
                      :class="`bullet-${resolveLabelColor(label)}`"
                    />
                    <span class="mail-date">{{ formatDateToMonthShort(email.time, { hour: 'numeric', minute: 'numeric', }) }}</span>
                  </div> -->
                </div>

                <div class="mail-message">
                  <p class="text-truncate mb-0">
                    {{ filterTags(email.pesanTerakhir.body) }}
                  </p>
                </div>
              </b-media-body>
            </b-media>
          </div>
          <div class="mb-2">
            <h4 class="chat-list-title ml-1 text-primary">
              Assigned
            </h4>
            <b-media
              v-for="email in emails"
              :key="email.id"
              tag="li"
              no-body
              
              @click="updateEmailViewData(email.id)"
              v-if="email.status === 'Assigned'"
            >
              <!-- Contacts Title -->
              
              <b-media-aside class="media-left mr-50">
                <b-avatar
                  class="avatar"
                  size="40"
                  variant="primary"
                  :text="avatarText(email.userId)"
                />
                <div class="user-action">
                  <!-- <b-form-checkbox
                    :checked="selectedEmails.includes(email.id)"
                    @change="toggleSelectedMail(email.id)"
                    @click.native.stop
                  /> -->
                  <div class="email-favorite">
                    <!-- <feather-icon
                      icon="StarIcon"
                      size="17"
                      :class="{ 'text-warning fill-current': email.isStarred }"
                      @click.stop="toggleStarred(email)"
                    /> -->
                  </div>
                </div>
              </b-media-aside>

              <b-media-body>
                <div class="mail-details">
                  <div class="mail-items">
                    <h5 class="mb-25">
                      {{ email.userId }}
                    </h5>
                    <span class="text-truncate">{{ email.pesanTerakhir.subject }}</span>
                  </div>
                  <!-- <div class="mail-meta-item">
                    <feather-icon
                      v-if="email.attachments.length"
                      icon="PaperclipIcon"
                    />
                    <span
                      v-for="label in email.labels"
                      :key="label"
                      class="mx-50 bullet bullet-sm"
                      :class="`bullet-${resolveLabelColor(label)}`"
                    />
                    <span class="mail-date">{{ formatDateToMonthShort(email.time, { hour: 'numeric', minute: 'numeric', }) }}</span>
                  </div> -->
                </div>

                <div class="mail-message">
                  <p class="text-truncate mb-0">
                    {{ filterTags(email.pesanTerakhir.body) }}
                  </p>
                </div>
              </b-media-body>
            </b-media>
          </div>
          <div class="mb-2">
            <h4 class="chat-list-title ml-1 text-primary">
              Answered
            </h4>
            <b-media
              v-for="email in emails"
              :key="email.id"
              tag="li"
              no-body
              
              @click="updateEmailViewData(email.id)"
              v-if="email.status === 'Answered' || email.status === 'Resolved'"
            >
              <!-- Contacts Title -->
              
              <b-media-aside class="media-left mr-50">
                <b-avatar
                  class="avatar"
                  size="40"
                  variant="primary"
                  :text="avatarText(email.userId)"
                />
                <div class="user-action">
                  <!-- <b-form-checkbox
                    :checked="selectedEmails.includes(email.id)"
                    @change="toggleSelectedMail(email.id)"
                    @click.native.stop
                  /> -->
                  <div class="email-favorite">
                    <!-- <feather-icon
                      icon="StarIcon"
                      size="17"
                      :class="{ 'text-warning fill-current': email.isStarred }"
                      @click.stop="toggleStarred(email)"
                    /> -->
                  </div>
                </div>
              </b-media-aside>

              <b-media-body>
                <div class="mail-details">
                  <div class="mail-items">
                    <h5 class="mb-25">
                      {{ email.userId }}
                    </h5>
                    <span class="text-truncate">{{ email.pesanTerakhir.subject }}</span>
                  </div>
                  <!-- <div class="mail-meta-item">
                    <feather-icon
                      v-if="email.attachments.length"
                      icon="PaperclipIcon"
                    />
                    <span
                      v-for="label in email.labels"
                      :key="label"
                      class="mx-50 bullet bullet-sm"
                      :class="`bullet-${resolveLabelColor(label)}`"
                    />
                    <span class="mail-date">{{ formatDateToMonthShort(email.time, { hour: 'numeric', minute: 'numeric', }) }}</span>
                  </div> -->
                </div>

                <div class="mail-message">
                  <p class="text-truncate mb-0">
                    {{ filterTags(email.pesanTerakhir.body) }}
                  </p>
                </div>
              </b-media-body>
            </b-media>
          </div>
        </ul>
        <!-- <div
          class="no-results"
          :class="{'show': !emails.length}"
        >
          <h5>No Items Found</h5>
        </div> -->
      </vue-perfect-scrollbar>
    </div>

    <!-- Email View/Detail -->
    <email-view
      :class="{'show': showEmailDetails}"
      :email-view-data="emailViewData"
      :opended-email-meta="opendedEmailMeta"
      @close-email-view="showEmailDetails = false"
      @move-email-to-folder="moveOpenEmailToFolder"
      @toggle-email-starred="toggleStarred(emailViewData)"
      @update-email-label="updateOpenEmailLabel"
      @mark-email-unread="markOpenEmailAsUnread"
      @change-opened-email="changeOpenedEmail"
      :shall-show-email-compose-modal.sync="shallShowEmailComposeModal"
      @bukasidebarr="bukasidebar"
      @load-data="updateEmailViewData"
      @data-baru ="fetchEmails"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <email-left-sidebar
        :shall-show-email-compose-modal.sync="shallShowEmailComposeModal"
        :emails-meta="emailsMeta"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>

    <!-- Compose Email Modal -->
    <email-compose :email-view-data="emailViewData" :subjectemail="subjectemail" v-model="shallShowEmailComposeModal" />

    <!-- <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
      :pesan="emailViewData || {}"
    /> -->
  </div>
</template>

<script>
import store from '@/store'
import {
  ref, onUnmounted, computed, watch,onMounted
  // ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend, BDropdown, BDropdownItem,
  BFormCheckbox, BMedia, BMediaAside, BMediaBody, BAvatar,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { filterTags, formatDateToMonthShort } from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import EmailLeftSidebar from './EmailLeftSidebar.vue'
import EmailView from './EmailView.vue'
import emailStoreModule from './emailStoreModule'
import useEmail from './useEmail'
import EmailCompose from './EmailCompose.vue'
import axios from "axios"
import Pusher from 'pusher-js'
import { avatarText } from '@core/utils/filter'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,

    // 3rd Party
    VuePerfectScrollbar,

    // App SFC
    EmailLeftSidebar,
    EmailView,
    EmailCompose,
    ChatActiveChatContentDetailsSidedbar
  },
  setup() {
    const EMAIL_APP_STORE_MODULE_NAME = 'app-email'
    const userData = JSON.parse(localStorage.getItem('userData'));
    const url = localStorage.getItem('baseapi');
    const shallShowActiveChatContactSidebar = ref(false)

    // Register module
    if (!store.hasModule(EMAIL_APP_STORE_MODULE_NAME)) store.registerModule(EMAIL_APP_STORE_MODULE_NAME, emailStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EMAIL_APP_STORE_MODULE_NAME)) store.unregisterModule(EMAIL_APP_STORE_MODULE_NAME)
    })

    const { route, router } = useRouter()
    const { resolveLabelColor } = useEmail()

    const datapusher = ref([])

    onMounted(() =>{
      Pusher.logToConsole = true;
      const pusher = new Pusher('17d50bafa08cd75d138a',{
        cluster: 'ap1'
      });

      var channel = pusher.subscribe('Email-channel');
      channel.bind('Email-event', function(data) {
        datapusher.value.push({
          id: data.chat_id,
          userId: data.pengirim,
          penerima:data.penerima,
          pesan:data.pesan
        });
        console.log(data)
        // var test= JSON.parse(datapusher.value);
        // console.log(test)

       
        console.log("ini data pusher"+datapusher.value)
        fetchEmails()
        updateEmailViewData(data.chat_id)
      });

      
    })

    // Route Params
    const routeParams = computed(() => route.value.params)
    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      // Pusher.logToConsole = true;
      // const pusher = new Pusher('3d8dd8625fe4244d8b52',{
      //   cluster: 'ap1'
      // });

      // var channel = pusher.subscribe('Email-channel');
      // channel.bind('Email-event', function(data) {
      //   console.log(data)
      //   // chatUnmaped.value.push({
      //   //   id: data.chat_id,
      //   //   userId: data.pengirim,
      //   //   pesanTerakhir: data.pesan,
      //   // })
      //   console.log("ini data pusher"+datapusher.value)

      // });
      // fetchEmails()
    })

    // Emails & EmailsMeta
    const emails = ref([])
    const emailsMeta = ref({})

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // Search Query
    const routeQuery = computed(() => route.value.query.q)
    const searchQuery = ref(routeQuery.value)
    watch(routeQuery, val => {
      searchQuery.value = val
    })
    // eslint-disable-next-line no-use-before-define
    watch(searchQuery, () => fetchEmails())
    const updateRouteQuery = val => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      if (val) currentRouteQuery.q = val
      else delete currentRouteQuery.q

      router.replace({ name: route.name, query: currentRouteQuery })
    }

    const fetchEmails = () => {
         axios.get(url+'room?filter=channel,=,Email&include=unseen,chat',{
          q: searchQuery.value,
          folder: router.currentRoute.params.folder || 'inbox',
          label: router.currentRoute.params.label,
            headers: {
              'xth': userData.token
            }
          })
        .then(response => {
          var data = response.data.data
          console.log(data)

          var chatcon = []

          for(var i= 0; i< data.length; i++){
          
            var lastPosition = data[i].chat
            var pesan
            if(lastPosition.length == 0){
              pesan = "Tidak Ada"
            }else{
              pesan = data[i].chat[lastPosition.length-1].pesan;
            }
            
            
            chatcon.push({
              id: data[i].chat_id,
              userId: data[i].owner,
              status: data[i].status,
              channel: data[i].channel,
              unseenMsgs: data[i].unseen,
              pesanTerakhir: pesan,
              chat: data[i].chat
            })
          }
          emails.value = chatcon
          console.log('ini email')
          console.log(emails.value)
          emailsMeta.value = response.data.emailsMeta
          console.log('test')
        })
    }

    fetchEmails()

    const bukasidebar = data => {
      data = true
      shallShowActiveChatContactSidebar.value = data
      console.log('bukasidebar')
    }

    // ------------------------------------------------
    // Mail Selection
    // ------------------------------------------------
    const selectedEmails = ref([])
    const toggleSelectedMail = mailId => {
      const mailIndex = selectedEmails.value.indexOf(mailId)

      if (mailIndex === -1) selectedEmails.value.push(mailId)
      else selectedEmails.value.splice(mailIndex, 1)
    }
    const selectAllEmailCheckbox = computed(() => emails.value.length && (emails.value.length === selectedEmails.value.length))
    const isSelectAllEmailCheckboxIndeterminate = computed(() => Boolean(selectedEmails.value.length) && emails.value.length !== selectedEmails.value.length)
    const selectAllCheckboxUpdate = val => {
      selectedEmails.value = val ? emails.value.map(mail => mail.id) : []
    }
    // ? Watcher to reset selectedEmails is somewhere below due to watch dependecy fullfilment

    // ------------------------------------------------
    // Mail Actions
    // ------------------------------------------------
    const toggleStarred = email => {
      store.dispatch('app-email/updateEmail', {
        emailIds: [email.id],
        dataToUpdate: { isStarred: !email.isStarred },
      }).then(() => {
        // eslint-disable-next-line no-param-reassign
        email.isStarred = !email.isStarred
      })
    }

    const moveSelectedEmailsToFolder = folder => {
      store.dispatch('app-email/updateEmail', {
        emailIds: selectedEmails.value,
        dataToUpdate: { folder },
      })
        .then(() => { fetchEmails() })
        .finally(() => { selectedEmails.value = [] })
    }

    const updateSelectedEmailsLabel = label => {
      store.dispatch('app-email/updateEmailLabels', {
        emailIds: selectedEmails.value,
        label,
      })
        .then(() => { fetchEmails() })
        .finally(() => { selectedEmails.value = [] })
    }

    const markSelectedEmailsAsUnread = () => {
      store.dispatch('app-email/updateEmail', {
        emailIds: selectedEmails.value,
        dataToUpdate: { isRead: false },
      })
        .then(() => { fetchEmails() })
        .finally(() => { selectedEmails.value = [] })
    }

    // ------------------------------------------------
    // Email Details
    // ------------------------------------------------
    const showEmailDetails = ref(false)
    const emailViewData = ref({})
    const opendedEmailMeta = computed(() => {
      const openedEmailIndex = emails.value.findIndex(e => e.id === emailViewData.value.id)
      return {
        hasNextEmail: Boolean(emails.value[openedEmailIndex + 1]),
        hasPreviousEmail: Boolean(emails.value[openedEmailIndex - 1]),
      }
    })

    const subjectemail = ref('')
    const updateEmailViewData = id => {
      // Mark email is read
      axios.get(url+'room?filter=chat_id,=,'+id+',channel,=,Email&first=ya&include=unseen,chat',{
        headers:{
          'xth': userData.token
        }
      })
      // store.dispatch('app-email/updateEmail', {
      //   emailIds: [email.id],
      //   dataToUpdate: { isRead: true },
      // })
        .then(response => {
          // If opened email is unread then decrease badge count for email meta based on email folder
          // if (!email.isRead && (email.folder === 'inbox' || email.folder === 'spam')) {
          //   emailsMeta.value[email.folder] -= 1
          // }
          var data = response.data.data
          console.log('emailviewdata')
          console.log(response.data)

          var datachat = data.chat

          // Untuk mendapatkan pesan body paling terakhir dari pengirim untuk dijadikan subject kirim email
          var datapesan = []
          for (let i = 0; i < datachat.length; i++) {
            if (datachat[i].pengirim === 'Me' || datachat[i].pengirim === userData.nama) { continue; }
            // console.log(datachat[i].pesan)
            datapesan.push(datachat[i].pesan)
            console.log(datapesan)
          }
          subjectemail.value = "Re: "+datapesan[datapesan.length-1].subject
          console.log(subjectemail)

          
          // email.isRead = true
          emailViewData.value = data
          showEmailDetails.value = true
        }).catch(error =>{
          console.log(error)
        })
        // .finally(() => {
          // emailViewData.value = email
          // showEmailDetails.value = true
        // })
    }
    const moveOpenEmailToFolder = folder => {
      selectedEmails.value = [emailViewData.value.id]
      moveSelectedEmailsToFolder(folder)
      selectedEmails.value = []
      showEmailDetails.value = false
    }
    const updateOpenEmailLabel = label => {
      selectedEmails.value = [emailViewData.value.id]
      updateSelectedEmailsLabel(label)

      // Update label in opened email
      const labelIndex = emailViewData.value.labels.indexOf(label)
      if (labelIndex === -1) emailViewData.value.labels.push(label)
      else emailViewData.value.labels.splice(labelIndex, 1)

      selectedEmails.value = []
    }

    const markOpenEmailAsUnread = () => {
      selectedEmails.value = [emailViewData.value.id]
      markSelectedEmailsAsUnread()

      selectedEmails.value = []
      showEmailDetails.value = false
    }

    const changeOpenedEmail = dir => {
      const openedEmailIndex = emails.value.findIndex(e => e.id === emailViewData.value.id)
      const newEmailIndex = dir === 'previous' ? openedEmailIndex - 1 : openedEmailIndex + 1
      emailViewData.value = emails.value[newEmailIndex]
    }

    // * If someone clicks on filter while viewing detail => Close the email detail view
    watch(routeParams, () => {
      showEmailDetails.value = false
    })

    // * Watcher to reset selectedEmails
    // ? You can also use showEmailDetails (instead of `emailViewData`) but it will trigger execution twice in this case
    // eslint-disable-next-line no-use-before-define
    watch([emailViewData, routeParams], () => {
      selectedEmails.value = []
    })

    // Compose
    const shallShowEmailComposeModal = ref(false)

    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      // UI
      perfectScrollbarSettings,

      // Emails & EmailsMeta
      emails,
      emailsMeta,

      // Mail Selection
      selectAllEmailCheckbox,
      isSelectAllEmailCheckboxIndeterminate,
      selectedEmails,
      toggleSelectedMail,
      selectAllCheckboxUpdate,

      // Mail Actions
      toggleStarred,
      moveSelectedEmailsToFolder,
      updateSelectedEmailsLabel,
      markSelectedEmailsAsUnread,

      // Email Details
      showEmailDetails,
      emailViewData,
      opendedEmailMeta,
      updateEmailViewData,
      moveOpenEmailToFolder,
      updateOpenEmailLabel,
      markOpenEmailAsUnread,
      changeOpenedEmail,

      // Search Query
      searchQuery,
      updateRouteQuery,

      // UI Filters
      filterTags,
      formatDateToMonthShort,

      // useEmail
      resolveLabelColor,

      // Compose
      shallShowEmailComposeModal,

      // Left Sidebar Responsiveness
      mqShallShowLeftSidebar,
      avatarText,
      shallShowActiveChatContactSidebar,
      bukasidebar,
      fetchEmails,
      subjectemail
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
