<template>
<div style="height: inherit">
<div
      class="body-content-overlay"
      :class="{'show':shallShowActiveChatContactSidebar }"
      @click="shallShowActiveChatContactSidebar = false"
    />
  <!-- <section class="chat-app-window"> -->
  <b-card no-body v-for="message in message.chat" :key="message">
    <b-card-header
      class="email-detail-head"
    >
      <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
        <b-avatar
          size="48"
          :text="avatarText(message.pengirim)"
          class="mr-75"
          @click.native="shallShowActiveChatContactSidebar=true"
        />
        <div class="mail-items">
          <h5 class="mb-0">
            {{ message.pengirim }}
          </h5>
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0"
            class="email-info-dropup"
          >
            <template #button-content>
              <span v-if="message.pengirim === 'Me' || message.pengirim === userData.nama" class="font-small-3 text-muted mr-25">lwpi@mysurvey.id</span>
              <span v-else class="font-small-3 text-muted mr-25">{{ message.chat_id }}</span>
              <feather-icon
                icon="ChevronDownIcon"
                size="10"
              />
            </template>
            <table class="table table-sm table-borderless font-small-3">
              <tbody>
                <tr>
                  <td class="text-right text-muted align-top">
                    From:
                  </td>
                  <td>{{ message.pengirim }}</td>
                </tr>
                <tr>
                  <td class="text-right text-muted align-top">
                    To:
                  </td>
                  <td>{{ message.penerima }}</td>
                </tr>
                <tr>
                  <td class="text-right text-muted align-top">
                    Date:
                  </td>
                  <td>{{ message.created_at }}</td>
                </tr>
              </tbody>
            </table>
          </b-dropdown>
        </div>
      </div>
      <div class="mail-meta-item d-flex align-items-center">
        <small class="mail-date-time text-muted">{{ formatDate(message.created_at) }}</small>
        <!-- Mail Action DD -->
        <!-- <b-dropdown
          variant="link"
          no-caret
          toggle-class="p-0"
          right
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="17"
              class="ml-50 text-body"
            />
          </template>

          <b-dropdown-item>
            <feather-icon icon="CornerUpLeftIcon" />
            <span class="align-middle ml-50">Reply</span>
          </b-dropdown-item>

          <b-dropdown-item>
            <feather-icon icon="CornerUpRightIcon" />
            <span class="align-middle ml-50">Forward</span>
          </b-dropdown-item>

          <b-dropdown-item>
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown> -->
      </div>
    </b-card-header>

    <b-card-body class="mail-message-wrapper pt-2">
      <!-- eslint-disable vue/no-v-html -->
      <div v-if="message.pengirim === 'Me' || message.pengirim === userData.nama">
          
      </div>
      <div v-else  class="form-group form-check" style="margin-bottom:20px">
        <input type="checkbox"  v-model="cekdata" class="form-check-input" id="exampleCheck1" :value='message.pesan.body' @change="ambilPertanyaan(cekdata)">
      </div>
      <h5 class="font-weight-bolder text-body mb-0">
          Subject : 
      </h5>
      <div
        class="mail-message"
      >{{ message.pesan.subject }}</div>
      <br>
      <h5 class="font-weight-bolder text-body mb-0">
          Pesan : 
      </h5>
      <!-- <br> -->
      <div
        class="mail-message"
        v-html="message.pesan.body"
      />
      <br>
      <h5 class="font-weight-bolder text-body mb-0">
          Attachment :
      </h5>
      <!-- <br> -->
      <div class="mail-message">
        <ul v-for="file in message.pesan.attachment" :key="file">
          <li>
            <a :href="file">{{file}}</a>
          </li>
        </ul>
      </div>
      <!-- eslint-enable -->
    </b-card-body>

    <!-- <b-card-footer v-if="message.attachments && message.attachments.length">
      <div class="mail-attachments">
        <div class="d-flex align-items-center mb-1">
          <feather-icon
            icon="PaperclipIcon"
            size="16"
          />
          <h5 class="font-weight-bolder text-body mb-0 ml-50">
            {{ message.attachments.length }} Attachment{{ message.attachments.length > 1 ? 's' : '' }}
          </h5>
        </div>

        <div class="d-flex flex-column">
          <b-link
            v-for="(attachment, index) in message.attachments"
            :key="index"
            :href="attachment.url"
            target="_blank"
            :class="{'mt-75': index}"
          >
            <b-img
              :src="attachment.thumbnail"
              width="16px"
              class="mr-50"
            />
            <span class="text-muted font-weight-bolder align-text-top">{{ attachment.fileName }}</span>
            <span class="text-muted font-small-2 ml-25">({{ attachment.size }})</span>
          </b-link>
        </div>
      </div>
    </b-card-footer> -->
  </b-card>
  <!-- </section> -->
  <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
      :clusterJawaban="cluster || {}"
      :dataPertanyaan="chekedPertanyaan"
    />
</div>
</template>

<script>
import {
  BDropdown, BDropdownItem, BCard, BCardHeader, BCardBody, BCardFooter, BAvatar, BLink, BImg,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import {
  ref, onUnmounted, computed, watch,
  // ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import axios from "axios"

export default {
  components: {
    BDropdown, BDropdownItem, BCard, BCardHeader, BCardBody, BCardFooter, BAvatar, BLink, BImg,ChatActiveChatContentDetailsSidedbar,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    Jawaban:{
      type: Array,
      required:true,
    },
  },
  data(){
    return{
      cekdata:[]
    }
  },
  setup() {
    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)
    const test = ref()
    const userData = JSON.parse(localStorage.getItem('userData'));
    const url = localStorage.getItem('baseapi');

    
    const chekedPertanyaan = ref('')
    const cluster = ref([])

    const ambilPertanyaan = data =>{
      var dataku =[]
      for(var i = 0;i<data.length;i++){
        dataku.push(data[i])
      }
      // chekedPertanyaan.value = data.join(' ')
      // console.log(chekedPertanyaan.value)
      chekedPertanyaan.value = dataku.join(' ')
      console.log(chekedPertanyaan)

      axios.post(url+'cluster_recomendation',{
        pertanyaan: chekedPertanyaan.value
      },
      {
        headers: {
          xth: userData.token
        }
      }
      )
      .then(function (response) {
        cluster.value = response.data.data
        console.log(cluster);
      })
      .catch(function (error) {
        console.log(error);
      });
        }

    return {
      formatDate,
      ChatActiveChatContentDetailsSidedbar,
      shallShowActiveChatContactSidebar,
      test,
      avatarText,
      userData,
      ambilPertanyaan,
      cluster,
      chekedPertanyaan
    }
  },
}
</script>

<style>

</style>
